import logo from "../images/fast_white.png";

function Loading() {
    const size = window.outerWidth > 500 ? 200: 100;
    return (
        <section className="bg-mainBlue h-screen w-screen my-0 flex flex-col justify-center items-center">
            <img src={logo} alt="logo" className="w-96 mt-20"/>
            <div className="loading-animation">
                <svg fill="#FFFFFF" height={size} width={size} viewBox="0 0 511.998 511.998"
                    style={{ transform: 'scaleX(-1)', enableBackground: "new 0 0 511.998 511.998" }} 
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M510.652,230.062c-1.357-2.116-3.567-3.524-6.05-3.857l-128-17.067c-4.617-0.606-8.96,2.662-9.591,7.33
                            c-0.631,4.668,2.662,8.969,7.33,9.591l18.193,2.423v21.675l-42.667-4.574v-42.47l15.633-23.45c1.707-2.56,1.903-5.837,0.521-8.576
                            c-1.391-2.748-4.147-4.54-7.219-4.685l-179.2-8.533c-2.876-0.026-5.666,1.212-7.347,3.567l-42.667,59.733
                            c-0.171,0.239-0.205,0.529-0.35,0.777L9.438,209.113c-2.423-0.256-4.813,0.521-6.613,2.133C1.033,212.868,0,215.172,0,217.595
                            c0,75.281,88.047,136.533,196.267,136.533h273.067c3.746,0,7.057-2.441,8.158-6.025l34.133-110.933
                            C512.358,234.773,512,232.17,510.652,230.062z M409.6,251.728v-20.975l34.133,4.548v20.335l-34.185-3.661
                            C409.549,251.891,409.6,251.814,409.6,251.728z M264.047,178.982l78.899,3.755l-8.713,13.056c-0.93,1.399-1.434,3.055-1.434,4.736
                            v43.23l-76.834-8.235L264.047,178.982z M183.441,175.142l63.479,3.021l-7.902,55.287c-0.009,0.085,0.026,0.171,0.017,0.256
                            l-90.505-9.694L183.441,175.142z M463.027,337.062H196.267c-75.418,0-140.015-31.258-166.417-75.332l441.267,49.033
                            L463.027,337.062z M476.228,294.165L21.564,243.647c-1.809-5.385-3.26-10.863-3.925-16.486l463.915,49.698L476.228,294.165z
                            M486.665,260.245l-25.865-2.773V237.58l31.548,4.207L486.665,260.245z"/>
                </svg>
            </div>
        </section>
        
    );
}

export default Loading;