import React, { useState, useRef, useEffect } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import FlipPage from 'react-flip-page';

const ImageFlipBook = ({ images = [] }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageDimensions, setPageDimensions] = useState({ width: 0, height: 0 });
  const flipPageRef = useRef(null);

  useEffect(() => {
    if (images.length > 0) {
      const img = new Image();
      img.src = images[0];
      img.onload = () => {
        const { width, height } = img;
        setPageDimensions({ width, height });
      };
    }
  }, [images]);

  const handlePreviousPage = () => {
    if (flipPageRef.current) {
      flipPageRef.current.gotoPreviousPage();
    }
  };

  const handleNextPage = () => {
    if (flipPageRef.current) {
      flipPageRef.current.gotoNextPage();
    }
  };

  const pageWidth = window.innerWidth * 0.8;
  const pageHeight = pageWidth * (pageDimensions.height / pageDimensions.width);

  return (
    <div className="flex items-center justify-center w-screen h-screen md:mt-5 mt-2">
      <div className="relative h-auto">
        {images.length > 0 ? (
          <FlipPage
            ref={flipPageRef}
            orientation="horizontal"
            uncutPages
            width={pageWidth}
            height={pageHeight}
            pageBackground="#fff"
            onPageChange={(index) => setCurrentPage(index + 1)}
            className="flex items-center justify-center"
          >
            {images.map((src, index) => (
              <article key={`page_${index + 1}`} className="flip-page flex justify-center items-center h-full">
                <img
                  src={src}
                  alt={`Page ${index + 1}`}
                  className="shadow-lg max-w-full max-h-full object-contain"
                  width={pageWidth}
                  height={pageHeight}
                />
              </article>
            ))}
          </FlipPage>
        ) : (
          <div className="text-center text-white">No images to display</div>
        )}
        <div className="absolute top-1/2 transform -translate-y-1/2 w-full flex justify-between px-4">
          <button
            onClick={handlePreviousPage}
            disabled={currentPage <= 1}
            className="text-white bg-gray-500 bg-opacity-60 hover:bg-gray-700 rounded-full p-3 disabled:opacity-50"
            title="Prev Page"
          >
            <FaArrowLeft size={24} />
          </button>
          <button
            onClick={handleNextPage}
            disabled={currentPage >= images.length}
            className="text-white bg-gray-500 hover:bg-gray-700 rounded-full p-3 disabled:opacity-50"
            title="Next Page"
          >
            <FaArrowRight size={24} />
          </button>
        </div>
        <p className="text-white self-center text-center mt-5">
          Page {currentPage} of {images.length}
        </p>
      </div>
    </div>
  );
};

export default ImageFlipBook;
