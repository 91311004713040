import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getEventById, getEventImages } from '../services/gallery';
import Loading from './Loading';
import NavBar from './NavBar';

function Gallery() {
  const { id } = useParams();
  const [event, setEvent] = useState(null);
  const [images, setImages] = useState([]);
  const [model, setModel] = useState(false);
  const [tempImgSrc, setTempImgSrc] = useState("");

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const eventData = await getEventById(id);
        setEvent(eventData);

        const unsub = getEventImages(eventData.id, setImages);
        return () => unsub();
      } catch (error) {
        console.error("Error fetching event data:", error);
      }
    };
    fetchEvent();
  }, [id]);

  const getImg = (src) => {
    setTempImgSrc(src);
    setModel(true);
  };

  const closeModal = () => {
    setModel(false);
    setTempImgSrc("");
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      closeModal();
    }
  };

  useEffect(() => {
    if (model) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [model]);

  if (!event) {
    return <Loading />;
  }

  return (
    <>
      {model && (
        <div 
          className="fixed inset-0 w-screen h-screen flex justify-center items-center bg-black bg-opacity-75 z-50" 
          onClick={closeModal}
          tabIndex={-1}
        >
          <div className="relative">
            <button 
              className="absolute top-0 right-0 mt-4 mr-4 text-white text-3xl"
              onClick={closeModal}
            >
              &times;
            </button>
            <img 
              src={tempImgSrc} 
              className="block w-auto h-auto max-w-screen max-h-screen py-4" 
              alt="event-img"
              onClick={(e) => e.stopPropagation()}
            />
          </div>
        </div>
      )}
      <div className="flex flex-col justify-center items-center text-mainBlue gap-16">
        <section className={`absolute top-0 left-0 ${model ? "hidden" : "visible"}`}>
          <NavBar activeSection="" />
        </section>
        
        <h2 className="text-5xl font-bold mb-4 mt-32">{event.title}</h2>
        <h5 className="text-xl font-bold mb-4">{event.description}</h5>
        <ul className="md:columns-3 columns-2 gap-0 mx-5">
          {images.map((image, index) => (
            <li 
              key={index} 
              className="hover:opacity-70 cursor-pointer m-1" 
              onClick={() => getImg(image.url)} 
            >
              <img src={image.url} alt={event.title} className="w-full h-auto"/>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default Gallery;
