import React from 'react';
import { useParams } from 'react-router-dom';
import NavBar from './NavBar.jsx';
import ImageFlipBook from './ImageFlipBook.jsx';

function NewsletterViewer() {
  const { id } = useParams();

  const newsletters = [
    {
      title: "Newsletter 2023/2024",
      images: [
        "/newsletter/23_24/1_newsletter.png",
        "/newsletter/23_24/2_newsletter.png",
        "/newsletter/23_24/3_newsletter.png",
        "/newsletter/23_24/4_newsletter.png",
        "/newsletter/23_24/5_newsletter.png",
        "/newsletter/23_24/6_newsletter.png",
        "/newsletter/23_24/7_newsletter.png",
        "/newsletter/23_24/8_newsletter.png",
        "/newsletter/23_24/9_newsletter.png",
        "/newsletter/23_24/10_newsletter.png",
        "/newsletter/23_24/11_newsletter.png",
        "/newsletter/23_24/12_newsletter.png"
      ]
    }
  ];

  const newsletter = newsletters[id - 1];

  return (
    <>
      <NavBar activeSection="" />
      <div className="min-h-screen bg-mainBlue flex flex-col items-center pt-48 pb-10">
        <h1 className="md:text-4xl text-2xl font-bold text-white mt-5">{newsletter.title}</h1>
        <ImageFlipBook images={newsletter.images} />
      </div>
    </>
  );
}

export default NewsletterViewer;
