import { db } from "../firebase";
import { collection, onSnapshot, doc, getDoc } from "firebase/firestore";

export const getEvents = (setEvents) => {
    const unsub = onSnapshot(collection(db, 'gallery'), (snapshot) => {
        const eventsArray = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));

        const publishedEvents = eventsArray.filter((event) => event.isPublished);
        publishedEvents.reverse();
        setEvents(publishedEvents);
    });
    return unsub;
}

export const getEventById = async (id) => {
    const eventDocRef = doc(db, 'gallery', id);

    try {
        const eventDoc = await getDoc(eventDocRef);
        if (eventDoc.exists()) {
            return {
                id: eventDoc.id,
                ...eventDoc.data(),
            };
        } else {
            throw new Error("Event not found");
        }
    } catch (error) {
        throw error;
    }
};

export const getEventImages = (eventId, setImages) => {
    const unsub = onSnapshot(collection(db, 'gallery', eventId, 'images'), (snapshot) => {
        const imagesArray = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));
        setImages(imagesArray);
    });
    return unsub;
}