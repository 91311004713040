import { useState, useEffect } from 'react';
import logo from '../images/nav_logo.png';
import { FaBars } from 'react-icons/fa';

const NavBar = ({ activeSection }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const links = [
    { name: 'Team', link: '/#team' },
    { name: 'Departments', link: '/#departments' },
    { name: 'Sponsors', link: '/#sponsors' },
    { name: 'Gallery', link: '/#gallery' },
    { name: 'Newsletter', link: '/#newsletter' },
    { name: 'Recruitment', link: '/#recruitment' },
  ];

  return (
    <nav className="fixed top-0 w-screen z-50">
      <div className="flex flex-row items-center px-5 py-2 justify-between bg-white w-screen">
        <a href="/" className="py-3">
          <img src={logo} className="" alt="logo" width={200} />
        </a>
        <FaBars size={30} className="lg:hidden" onClick={() => setMenuOpen(!menuOpen)} />
        <ul className="lg:flex flex-row justify-around hidden gap-2">
          {links.map((link, index) => (
            <li key={index}>
              <a
                href={link.link}
                className={`px-4 py-2 rounded ${activeSection === link.name.toLowerCase() ? 'bg-mainBlue text-white' : 'hover:bg-mainBlue hover:text-white'}`}
              >
                {link.name}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <ul className={`${menuOpen ? 'block' : 'hidden'} lg:hidden flex flex-col items-center gap-10 py-10 w-screen bg-white bg-opacity-80`}>
        {links.map((link, index) => (
          <li key={index} className="w-full">
            <a
              href={link.link}
              className="px-4 py-2 bg-white bg-opacity-0 text-mainBlue w-full"
              onClick={() => setMenuOpen(false)}
            >
              {link.name}
              
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default NavBar;
