import React, { useState } from "react";
import Eletrical from "../images/departments/eletrical.svg";
import Mechanical from "../images/departments/mechanical.svg";
import Hull from "../images/departments/hull_body.svg";
import Management from "../images/departments/mm.svg";
import Members from "./Members.tsx";
import bgVideo from "../images/ocean.mp4";
import departments from "../images/departments.png";

function Departments() {
  const [department, setDepartment] = useState("");
  const size = window.outerWidth > 768 ? 32 : 18;

  return (
    <div className="bg-mainYellow md:pt-28 pt-20 min-h-screen relative">
      <div className="departments-bg flex flex-col w-full items-center pb-10 relative">
        <video
          autoPlay
          loop
          muted
          className="absolute top-0 left-0 w-full h-full object-cover z-0"
          playsInline
        >
          <source src={bgVideo} type="video/mp4" />
          <img src={departments} className="absolute top-0 left-0 w-full h-full object-cover z-0"/>
        </video>
        <h1 className="text-mainYellow font-custom font-bold md:text-8xl text-4xl mt-10 [text-shadow:_0_5px_0_rgb(0_0_0_/_20%)] z-10">
          DEPARTMENTS
        </h1>
        <ul className="flex items-center justify-center flex-row flex-wrap w-full gap-5 py-10 z-10 font-custom">
          <li
            className={`flex-col rounded-full md:h-48 md:w-48 w-28 h-28 inline-flex items-center justify-center text-center bg-white text-mainYellow md:text-xl text-xs font-bold hover:bg-opacity-30 ${department === "ES" && "bg-opacity-30"}`}
            onClick={() => department === "ES" ? setDepartment("") : setDepartment("ES")}
          >
            <img src={Eletrical} alt="Eletrical" className="lg:w-20 lg:h-20 md:w-15 md:h-15 w-10 h-10 mb-3" />
            ELECTRICAL SYSTEMS
          </li>
          <li
            className={`flex-col rounded-full md:h-48 md:w-48 w-28 h-28 inline-flex items-center justify-center text-center bg-white text-mainYellow md:text-xl text-xs font-bold hover:bg-opacity-30 ${department === "HB" && "bg-opacity-30"}`}
            onClick={() => department === "HB" ? setDepartment("") : setDepartment("HB")}
          >
            <img src={Hull} alt="Hull" className="lg:w-20 lg:h-20 md:w-15 md:h-15 w-10 h-10 mb-3" />
            HULL & BODY
          </li>
          <li
            className={`flex-col rounded-full md:h-48 md:w-48 w-28 h-28 inline-flex items-center justify-center text-center bg-white text-mainYellow md:text-xl text-xs font-bold hover:bg-opacity-30 ${department === "MM" && "bg-opacity-30"}`}
            onClick={() => department === "MM" ? setDepartment("") : setDepartment("MM")}
          >
            <img src={Management} alt="Management" className="lg:w-20 lg:h-20 md:w-15 md:h-15 w-10 h-10 mb-3" />
            MANAGEMENT & MARKETING
          </li>
          <li
            className={`flex-col rounded-full md:h-48 md:w-48 w-28 h-28 inline-flex items-center justify-center text-center bg-white text-mainYellow md:text-xl text-xs font-bold hover:bg-opacity-30 ${department === "MS" && "bg-opacity-30"}`}
            onClick={() => department === "MS" ? setDepartment("") : setDepartment("MS")}
          >
            <img src={Mechanical} alt="Mechanical" className="lg:w-20 lg:h-20 md:w-15 md:h-15 w-10 h-10 mb-3" />
            MECHANICAL SYSTEMS
          </li>
        </ul>
        <div className="z-10">
            <Members department={department} size={size} />
        </div>
      </div>
    </div>
  );
}

export default Departments;
