import React, { useEffect } from 'react';
import SplitType from 'split-type';
import gsap from 'gsap';
import '../index.css';

function Hero() {
    useEffect(() => {
        
        const splitText = new SplitType('.wtg', { types: 'words' });

        
        gsap.from(splitText.words, {
            opacity: 0,
            y: 40,
            duration: 1,
            stagger: { amount: 0.5 },
        });

       
        const words = document.querySelectorAll('.word');

        
        words.forEach((word) => {
            const tween = gsap.to(word, { yPercent: -30, yoyo: true, repeat: 1, paused: true });

            const handleMouseOver = () => {
                if (!gsap.isTweening(word)) {
                    tween.restart();
                }
            };

            word.addEventListener('mouseover', handleMouseOver);

            return () => {
                word.removeEventListener('mouseover', handleMouseOver);
            };
        });

    }, []);

    return (
        <div className="hero-bg my-0 h-screen">
            <div className="bg-transparent overflow-y-scroll snap-y snap-mandatory">
                <div className="hero-content w-screen h-screen flex justify-center items-end py-10 snap-center pb-32" id="target">
                    <h1 className="text-mainBlue text-center font-bold md:text-8xl text-6xl pb-20 pt-64 w-full wtg">
                        Towards a greener blue.
                    </h1>
                </div>
                <div className="hero-content h-screen text-white text-center w-screen flex justify-end items-center flex-col snap-center py-32">
                    <h2 className="mb-24 md:text-8xl text-5xl font-custom font-bold">ABOUT US</h2>
                    <p className="md:text-3xl text-2xl lg:px-44 px-10">
                        Founded in November 2023, the <span className="font-custom font-bold">FEUP Academic Solar Team</span> is a project involving students from various engineering degrees from Faculdade de Engenharia da Universidade do Porto (FEUP), Portugal. Our mission is to merge innovation with sustainability by developing a solar-powered competition vessel and address key technological challenges in the field, sailing on the power of the sun.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Hero;
