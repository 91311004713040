import { db } from "../firebase";
import { collection, getDocs } from "firebase/firestore";
export const getMembers = async () => {

    try {
          const querySnapshot = await getDocs(collection(db, "members"));
      
          const members = [];
          querySnapshot.forEach((doc) => {
            members.push({
              id: doc.id, 
              ...doc.data()
            });
          });
      
          return members;
        } catch (error) {
          console.error("Error fetching members:", error);
          return [];
    }
};