import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { getMembers } from '../services/members';

interface Member {
  id: string;
  name: string;
  role: string;
  photoURL: string;
  linkedin?: string;
  bio?: string;
}

interface MembersContextType {
  members: Member[];
  loading: boolean;
}

const MembersContext = createContext<MembersContextType>({
  members: [],
  loading: true,
});

export const useMembers = () => useContext(MembersContext);

export const MembersProvider = ({ children }: { children: ReactNode }) => {
  const [members, setMembers] = useState<Member[]>([]); 
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMembers = async () => {
      setLoading(true);
      const fetchedMembers = await getMembers();
      setMembers(fetchedMembers); 
      setLoading(false);
    };

    fetchMembers();
  }, []);

  return (
    <MembersContext.Provider value={{ members, loading }}>
      {children}
    </MembersContext.Provider>
  );
};
