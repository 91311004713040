import React, { useState } from "react";
import { FaUpload, FaEnvelope, FaUser, FaPhone, FaGraduationCap, FaCalendar, FaFile, FaPencilAlt } from "react-icons/fa";
import { submitRecruitment } from "../services/recruitment_submission";
import { ClipLoader } from 'react-spinners';

function Recruitment() {
    const [cvLabel, setCvLabel] = useState("CV (PDF)");
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        cv: null,
        course: "",
        year: "",
        motivation: ""
    });

    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData({ ...formData, cv: file });
        setCvLabel(file ? file.name : "CV (PDF)");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await submitRecruitment(formData);
            setFormData({ name: "", email: "", phone: "", cv: null, course: "", year: "", motivation: "" });
            setCvLabel("CV (PDF)");
        } catch (error) {
            console.error("Submission failed:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="pt-32 md:pt-20 z-10 h-min flex flex-col justify-center items-center">
            <h1 className="md:text-8xl text-4xl font-bold text-slate-100 text-center">RECRUITMENT</h1>
            <p className="md:text-4xl text-2xl font-bold text-slate-100 m-5 text-center md:w-3/5">At the moment, we are not in a recruitment phase. <br></br> However, if you wish to join the team, feel free to submit a spontaneous application.</p>
            <form className="flex flex-wrap items-center w-full max-w-4xl justify-center m-10" onSubmit={handleSubmit}>
                <div className="w-full md:w-1/2 flex flex-col items-center justify-center gap-3">
                    <div className="relative w-3/4">
                        <FaUser className="absolute left-3 top-1/2 transform -translate-y-1/2 text-mainBlue"/>
                        <input
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className="w-full text-left text-mainBlue rounded-lg p-3 pl-10 outline-none border border-gray-300"
                            type="text"
                            placeholder="Name"
                            required
                        />
                    </div>
                    <div className="relative w-3/4">
                        <FaEnvelope className="absolute left-3 top-1/2 transform -translate-y-1/2 text-mainBlue"/>
                        <input
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="w-full text-left text-mainBlue rounded-lg p-3 pl-10 outline-none border border-gray-300"
                            type="email"
                            placeholder="Email"
                            required
                        />
                    </div>
                    <div className="relative w-3/4">
                        <FaPhone className="absolute left-3 top-1/2 transform -translate-y-1/2 text-mainBlue"/>
                        <input
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            className="w-full text-left text-mainBlue rounded-lg p-3 pl-10 outline-none border border-gray-300"
                            type="tel"
                            placeholder="Phone Number"
                            required
                        />
                    </div>
                    <div className="relative w-3/4">
                        <FaGraduationCap className="absolute left-3 top-1/2 transform -translate-y-1/2 text-mainBlue"/>
                        <input
                            name="course"
                            value={formData.course}
                            onChange={handleChange}
                            className="w-full text-left text-mainBlue rounded-lg p-3 pl-10 outline-none border border-gray-300"
                            type="text"
                            list="courses"
                            placeholder="Course"
                            required
                        />
                        <datalist id="courses">
                            <option>Computer Engineering</option>
                            <option>Mechanical Engineering</option>
                            <option>Electrical Engineering</option>
                            <option>Industrial Engineering and Management</option>
                            <option>Bioengineering</option>
                            <option>Civil Engineering</option>
                            <option>Materials Engineering</option>
                            <option>Physics Engineering</option>
                            <option>Mining and Geo-Environmental Engineering</option>
                            <option>Chemical Engineering</option>
                            <option>Environmental Engineering</option>
                        </datalist>
                    </div>
                </div>
                <div className="w-full md:w-1/2 flex flex-col items-center gap-3 mt-3">
                    <div className="relative w-3/4">
                        <FaCalendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-mainBlue"/>
                        <input
                            name="year"
                            value={formData.year}
                            onChange={handleChange}
                            className="w-full text-left text-mainBlue rounded-lg p-3 pl-10 outline-none border border-gray-300"
                            type="number"
                            placeholder="Course Year"
                            min="1" 
                            max="5"
                            required
                        />
                    </div>
                    <div className="relative w-3/4">
                        <FaFile className="absolute left-3 top-1/2 transform -translate-y-1/2 text-mainBlue"/>
                        <label htmlFor="cv" className="block w-full text-left text-mainBlue bg-white rounded-lg p-3 pl-10 border border-gray-300 cursor-pointer">
                            {cvLabel}
                            <FaUpload className="absolute right-5 top-1/2 transform -translate-y-1/2 text-mainBlue"/>
                        </label>
                        <input
                            id="cv"
                            name="cv"
                            onChange={handleFileChange}
                            className="hidden"
                            type="file"
                            accept="application/pdf"
                            required
                        />
                    </div>
                    <div className="relative w-3/4">
                        <FaPencilAlt className="absolute left-3 top-6 transform -translate-y-1/2 text-mainBlue"/>
                        <textarea
                            name="motivation"
                            value={formData.motivation}
                            onChange={handleChange}
                            className="w-full text-left text-mainBlue rounded-lg p-3 pb-0 pl-10 outline-none border border-gray-300"
                            rows="4"
                            placeholder="Motivation"
                            required
                        ></textarea>
                    </div>
                </div>
                <div className="w-full flex justify-center mt-7">
                  <button className="text-2xl font-bold bg-mainBlue text-white rounded-lg p-2" type="submit" disabled={loading}>
                    {loading ? (
                      <ClipLoader size={24} color={"#fff"} loading={loading} />
                    ) : (
                      "SUBMIT"
                    )}
                  </button>
                </div>
            </form>
        </div>
    );
}

export default Recruitment;
