import { db } from "../firebase";
import { collection, addDoc, query, where, getDocs } from "firebase/firestore";

export const subscribeNewsletter = async (formData) => {
    const { name, email } = formData;
    try {
        const usersRef = collection(db, 'newsletter');
        const q = query(usersRef, where('email', '==', email)); 

        const querySnapshot = await getDocs(q);
        if (querySnapshot.size > 0) {
            alert("You are already subscribed to our newsletter!");
            return;
        } else {
            
            await addDoc(collection(db, "newsletter"), {
                name,
                email,
                timestamp: new Date()
            });

            alert("Thank you for subscribing to our newsletter!");
        }
    } catch (e) {
        console.error("Error adding document: ", e);
        alert("Failed to subscribe to newsletter. Please try again.");
    }
}
