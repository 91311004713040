import { Link } from "react-router-dom";

function EventSlide({ event }) {
  const link = `/${event.id}`;
  return (
    <div className="flex flex-col items-center bg-white shadow-lg rounded-lg overflow-hidden m-4">
      <div className="w-full">
        <img src={event.mainImage} alt={event.title} className="w-full h-48 object-cover"/>
      </div>
      <div className="p-6 text-center">
        <h3 className="text-2xl font-bold text-mainBlue mb-2">{event.title}</h3>
        <p className="text-gray-700 mb-4">{event.description}</p>
        <Link to={link} className="text-blue-500 hover:text-blue-700 font-semibold">
          View Event
        </Link>
      </div>
    </div>
  );
}

export default EventSlide;
