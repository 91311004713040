import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import Home from "./Home";
import Gallery from "./components/Gallery";
import emailjs from "emailjs-com";
import NewsletterPage from "./components/NewsletterPage";
import MemberPage from "./components/MemberPage";
import { MembersProvider } from "./context/membersContext.tsx";

function App() {
  useEffect(() => emailjs.init(process.env.REACT_APP_EMAIL_PUBLIC_USER_ID), []);

  return (
    <BrowserRouter>
      <MembersProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/:id" element={<Gallery />} />
          <Route path="/newsletter/:id" element={<NewsletterPage />} />
          <Route path="/member/:id" element={<MemberPage />} />
        </Routes>
      </MembersProvider>
    </BrowserRouter>
  );
}

export default App;
