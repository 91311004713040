import React, { useState } from 'react';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { FaEnvelope, FaUser } from 'react-icons/fa';
import { subscribeNewsletter } from '../services/newsletter_subscription';
import SponsorsBg from "../images/sponsors-bg.png";
import { ClipLoader } from 'react-spinners';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { useNavigate } from 'react-router-dom';
import Newsletter1Image from "../images/capa.png";

pdfjs.GlobalWorkerOptions.workerSrc = '/pdfjs-dist/pdf.worker.min.js';

function Newsletter() {
  const [formData, setFormData] = useState({
    name: "",
    email: ""
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await subscribeNewsletter(formData);
      setFormData({ name: "", email: "" });
    } catch (error) {
      console.error("Subscription failed:", error);
    } finally {
      setLoading(false);
    }
  };

  const newsletters = [
    {
      id: 1,
      title: "Newsletter 2023/2024",
      image: Newsletter1Image
    }
  ];

  const handleNewsletterClick = (newsletter) => {
    navigate(`/newsletter/${newsletter.id}`);
  };

  return (
    <div className="bg-mainBlue min-h-screen">
      <img src={SponsorsBg} alt="background" className="object-cover w-full h-full" />
      <div className="relative z-10 flex flex-col items-center text-center pt-10 px-5">
        <h1 className="md:text-8xl text-4xl font-bold text-white">NEWSLETTER</h1>
        <p className="md:text-3xl text-lg text-white m-5">RECEIVE UPDATES FROM OUR TEAM EVERY SEMESTER</p>
      </div>
      <div className="relative z-10 flex md:flex-row flex-col md:items-start items-center md:justify-center pt-10 px-5 md:px-0">
        <div className="flex flex-col items-center justify-center bg-mainBlue p-10 md:w-1/2 w-full">
          <form className="flex flex-col items-center w-full max-w-md" onSubmit={handleSubmit}>
            <div className="relative w-3/4 mt-5">
              <FaEnvelope className="absolute left-3 top-1/2 transform -translate-y-1/2 text-mainBlue" />
              <input
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full text-left text-mainBlue rounded-lg p-3 pl-10 outline-none"
                type="email"
                placeholder="Email"
                required
              />
            </div>
            <div className="relative w-3/4 mt-3">
              <FaUser className="absolute left-3 top-1/2 transform -translate-y-1/2 text-mainBlue" />
              <input
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full text-left text-mainBlue rounded-lg p-3 pl-10 outline-none"
                type="text"
                placeholder="Name"
                required
              />
            </div>
            <button className="text-2xl font-bold bg-white text-mainBlue hover:bg-slate-200 rounded-lg mt-7 p-2 w-fit" type="submit" disabled={loading}>
              {loading ? (
                <ClipLoader size={24} color={"#000"} loading={loading} />
              ) : (
                "SUBSCRIBE"
              )}
            </button>
          </form>
        </div>
        <div className="md:w-1/3 w-full p-10">
          <Slide slidesToScroll={1} slidesToShow={1} indicators={true} canSwipe={true}>
            {newsletters.map((newsletter) => (
              <div key={newsletter.id} className="flex justify-center cursor-pointer" onClick={() => handleNewsletterClick(newsletter)}>
                <img src={newsletter.image} alt={newsletter.title} className="w-auto h-96"/>
              </div>
            ))}
          </Slide>
        </div>
      </div>
    </div>
  );
}

export default Newsletter;
