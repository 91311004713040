import React from 'react';
import { Link } from 'react-router-dom'; 

interface MemberProps {
  photo?: string;
  name?: string;
  role?: string;
  linkedin?: string;
  size: number;
}

const Member = ({ name, role, linkedin, photo, size}: MemberProps) => {
  let id = name?.split(' ').join('-').toLowerCase();
  const pixelSize = `${size * 6}px`;

  return (
    <Link
      to={{
        pathname: `/member/${id}`,
      }}
      className="relative rounded-full overflow-hidden flex flex-col items-center justify-end bg-white group"
      style={{
        height: pixelSize,
        width: pixelSize,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundImage: `url(${photo})`, 
      }}
    >
      <img 
        src={photo}  
        alt={name || "Member photo"}  
        className="hidden" 
      />
      <div className="absolute inset-0 flex flex-col items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-black bg-opacity-50">
        <h2 className="text-center md:text-base text-xs font-semibold text-white w-4/5">{name || "Unknown Member"}</h2>
        <h3 className="text-center md:text-sm text-xs text-white md:px-10">{role || "No Role"}</h3>
      </div>
    </Link>
  );
};

export default Member;
