import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useMembers } from '../context/membersContext.tsx';
import NavBar from './NavBar.jsx';
import Loading from './Loading.jsx';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

const MemberPage = () => {
  const { id } = useParams();
  const location = useLocation();
  const { members, loading } = useMembers(); 
  const [member, setMember] = useState(location.state || null); 
  const relatedPhotos = member ? member.relatedPhotosURL : [];
  
  const iconSize = window.innerWidth > 768 ? 32 : 24;
  const slidesToShow = window.innerWidth > 768 ? 2 : 1;

  useEffect(() => {
    if (!member) {
      const foundMember = members.find((m) => m.name.split(' ').join('-').toLowerCase() === id);
      setMember(foundMember);
    }
  }, [id, member, members]);

  if (loading || !member) {
    return <Loading />;
  }

  return (
    <>
      <NavBar activeSection="" />
      <section className="min-h-screen w-screen bg-mainBlue pt-48 md:px-32 px-4">
        <div className="flex flex-col items-center w-full justify-center">
          <section className="md:w-1/4 w-full">
            <div className="flex flex-row justify-center items-center">
              <div>
                  <h1 className="md:text-4xl text-2xl font-bold text-white mt-5">{member.name}</h1>
                  <p className="md:text-3xl text-xl text-white my-5">{member.role}</p>
              </div>        
            </div>
            <img src={member.photoURL} className="w-full" alt={`${member.name}`} /> 
          </section>
          <div className="flex flex-row mt-10">
            {/* To add more links if needed*/}
            <a href={member.linkedin} className="hover:opacity-80">
                  <svg width={iconSize} height={iconSize} viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_3_545)">
                      <path d="M45.1135 0.140625H4.21038C2.251 0.140625 0.666626 1.6875 0.666626 3.6V44.6719C0.666626 46.5844 2.251 48.1406 4.21038 48.1406H45.1135C47.0729 48.1406 48.6666 46.5844 48.6666 44.6813V3.6C48.6666 1.6875 47.0729 0.140625 45.1135 0.140625ZM14.9073 41.0437H7.78225V18.1312H14.9073V41.0437ZM11.3448 15.0094C9.05725 15.0094 7.21038 13.1625 7.21038 10.8844C7.21038 8.60625 9.05725 6.75937 11.3448 6.75937C13.6229 6.75937 15.4698 8.60625 15.4698 10.8844C15.4698 13.1531 13.6229 15.0094 11.3448 15.0094ZM41.5697 41.0437H34.4541V29.9062C34.4541 27.2531 34.4072 23.8312 30.751 23.8312C27.0479 23.8312 26.4854 26.7281 26.4854 29.7188V41.0437H19.3791V18.1312H26.2041V21.2625H26.2979C27.2447 19.4625 29.5698 17.5594 33.0291 17.5594C40.2385 17.5594 41.5697 22.3031 41.5697 28.4719V41.0437Z" fill="white" />
                    </g>
                  </svg>
            </a>    
          </div>
          <h3 className="md:text-2xl text-xl text-white md:mt-20 mt-10 pb-32">{member.description || "No bio available"}</h3>
        </div>
        {relatedPhotos.length > 0 && (
            <>
              <div className="md:py-32 py-16">
                <Slide slidesToScroll={1} slidesToShow={slidesToShow} indicators={true} canSwipe={true}>
                  {relatedPhotos.map((photo, index) => (
                    <div key={index} className="flex justify-center items-center h-full w-full">
                      <img src={photo} className=" w-4/6 max-h-full" alt={`Slide ${index + 1}`} />
                    </div>
                  ))}
                </Slide>
              </div>
            </>
          )}
      </section> 
    
        
    </>
  );
};

export default MemberPage;
