import { Slide } from 'react-slideshow-image';
import EventSlide from './EventSlide';
import 'react-slideshow-image/dist/styles.css';

function Events({ events }) {
  return (
    <div className="flex items-center justify-center w-screen h-screen bg-white">
      <div className="w-full max-w-4xl">
        <h1 className="font-custom md:text-8xl text-4xl text-center font-bold text-mainBlue md:mb-32 my-12">Gallery</h1>
        <Slide slidesToScroll={1} slidesToShow={1} indicators={true} canSwipe={true}>
          {events.map((event) => (
            <EventSlide key={event.title} event={event} />
          ))}
        </Slide>
      </div>
    </div>
  );
}

export default Events;
