import React from "react";

function TempRecruitment() {
    return (
        <div className="relative pt-64 md:pt-20 z-10 min-h-screen flex flex-col justify-center items-center gap-32 md:gap-16">
            <h1 className="md:text-8xl text-4xl font-bold text-slate-100 text-center">RECRUITMENT</h1>
            <p className="md:text-4xl text-2xl font-bold text-slate-100 m-5 text-center md:w-3/5">At the moment, we are not recruiting. <br></br> However, stay tuned for exciting updates coming soon!</p>
        </div>
    );
}

export default TempRecruitment;
