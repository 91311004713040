import Feup from '../images/sponsors/feup_logo.png';
import DeMec from '../images/sponsors/demec_logo.png';
import Inegi from '../images/sponsors/inegi_logo.png';
import IPDJ from '../images/sponsors/ipdj_logo.png';

function Sponsors() {
    /*
   const prime_sponsors = [
        {link: 'https://www.catana.com/en/', logo: Catana},
        {link: 'https://elergone.pt/', logo: Elergone},
        {link: 'https://www.nelo.eu/', logo: Nelo},
   ];
*/
   const institutional_sponsors = [
        {name: "FEUP", link: 'https://www.fe.up.pt/', logo: Feup},
        {name: "DEMEC", link: 'https://sigarra.up.pt/feup/en/uni_geral.unidade_view?pv_unidade=4', logo: DeMec},
        {name: "INEGI", link: 'https://www.inegi.pt/pt/?', logo: Inegi},
        {name: "IPDJ", link: 'https://ipdj.gov.pt/', logo: IPDJ},
    ];

    return (
      <div className="flex flex-col justify-center items-center text-mainBlue min-h-screen py-10 bg-white z-10">
        <h1 className="md:text-8xl text-4xl text-center font-bold z-10">Sponsors</h1>
        {/*
        <div className="flex flex-col mt-20">
            <h3 className="md:text-5xl text-3xl text-center font-bold">PRIME</h3>
            <ul className="flex flew-row flex-wrap justify-evenly items-center mt-10 gap-10">
                {prime_sponsors.map((sponsor, index) => (
                    <a href={sponsor.link} className="flex justify-center items-center grayscale hover:grayscale-0 w-48 md:w-1/4">
                        <li key={sponsor.link}>
                            <img src={sponsor.logo} className='object-contain' alt='sponsor' />
                        </li>
                    </a>
                    
                ))}
            </ul>
        </div>
        */}

        <div>
            <h3 className="md:text-5xl text-2xl text-center md:mt-40 mt-10 font-bold">INSTITUTIONAL</h3>
            <ul className="flex flew-row flex-wrap justify-evenly items-center gap-10">
                {institutional_sponsors.map((sponsor, index) => (
                    <a href={sponsor.link} target="_blank" rel="noreferrer" className="flex justify-center items-center grayscale hover:grayscale-0 w-48 md:w-1/4">
                        <li key={sponsor.name}>
                            <img src={sponsor.logo} className='object-contain mt-10' alt={sponsor.name}></img>
                        </li>
                    </a>
                    
                ))}
            </ul>
        </div>
       
      </div>
    );
  }
  
  export default Sponsors;