import React from 'react';
import { useMembers } from '../context/membersContext.tsx';
import Member from './Member.tsx'; 
import { sortMembers } from '../data.js';

const Members = ({size, department}) => {
  const { members, loading } = useMembers();
  const filteredMembers = sortMembers(members, department);
  if (loading) {
    return <div>Loading...</div>;
  }
  return (

    <div className="flex items-center justify-center h-full w-full">
    <ul
      className={`bg-white bg-opacity-30 rounded-xl flex flex-row items-center justify-center flex-wrap md:gap-10 gap-2 overflow-y-scroll w-full md:w-2/3 p-5 mx-auto md:h-custom h-72`}
      style={{ flexGrow: filteredMembers.length === 1 ? 1 : 'unset' }}
    >
      {filteredMembers.map((member, index) => (
        <Member
          key={index}
          photo={member.photoURL}
          size={size}
          name={member.name}
          role={member.role}
          linkedin={member.linkedin}
        />
      ))}
    </ul>
    </div>
  );
};

export default Members;
